import React from "react"
import { graphql, navigate } from "gatsby"
import styled from "styled-components"

import Footer from "../components/Footer"
import Layout from "../components/Layout"
import Meta from "../components/Meta"
import Nav from "../components/Nav"
import MobileMenu from "../components/MobileMenu"

const Styledmsg = styled.h1`
  display: flex;
  justify-content: center;
  margin-top: 60px;
`
const StyledCTA = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px 0;
`

export default function FourOhFourPage({ data }) {
  const details = data.details
  return (
    <div className="page">
      <Meta title="Page not found" />
      <Layout>
        <Nav
          phoneNumber={details.phone}
          phoneIcon={details.contactIcon}
          name={details.shortName}
          logo={details.logo}
        />
        <MobileMenu
          phoneNumber={details.phone}
          phoneIcon={details.contactIcon}
          copyrightMessage={details.copyrightMessage}
          agencyName={details.agencyName}
          agencyLink={details.agencyLink}
        />
        <main className="content">
          <Styledmsg>Sorry! Page not found!</Styledmsg>
          <StyledCTA>
            <button
              onClick={e => {
                navigate(`/`)
              }}
            >
              Return home
            </button>
          </StyledCTA>
        </main>
        <Footer
          copyrightMessage={details.copyrightMessage}
          agencyName={details.agencyName}
          agencyLink={details.agencyLink}
        />
      </Layout>
    </div>
  )
}

export const siteDetails = graphql`
  query {
    details: sanityDetails {
      contactIcon {
        ...ImageWithPreview
      }
      logo {
        ...ImageWithPreview
      }
      phone
      longName
      copyrightMessage
      agencyName
      agencyLink
    }
  }
`
